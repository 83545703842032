.custom-datepicker-dark,
.react-datepicker-dark__calendar-container {
    background-color: #1a202c;
    outline: none;
    border-radius: 5px;
    text-align: center;
}

.custom-datepicker-light,
.react-datepicker-light-container {
    background-color: #fff;
    outline: none;
}
